import React from 'react';
import NextHead from 'next/head';

export interface HeadProps {
  title: string;
  description: string;
  image: {
    url: string;
  };
}

const Head: React.FunctionComponent<HeadProps> = ({
  title,
  description,
  image,
}) => (
  <NextHead>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="icon" href={'/favicon.ico'} />
    <link rel="manifest" href={'/manifest.json'} />
    {/* <meta property="og:url" content={fullPath} /> */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={`${image.url}`} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="viewport"
      content="initial-scale=1, maximum-scale=1, user-scalable=no"
    />
    {process.env.PWA && process.env.ENV === 'production' && (
      <>
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={'/icons/apple-icon-120.jpg'}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={'/icons/apple-icon-152.jpg'}
        />
        <link
          rel="apple-touch-icon"
          sizes="167x167"
          href={'/icons/apple-icon-167.jpg'}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={'/icons/apple-icon-180.jpg'}
        />
      </>
    )}
  </NextHead>
);

export default Head;
